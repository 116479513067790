import React, { ChangeEvent, useEffect } from 'react';
import { Box } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { EPCISEventType, publicAPI } from 'react-component-utils';
import { LoadingButton } from '@mui/lab';
import { VerifiableCredential } from '@vckit/core-types';
import moment from 'moment';

import { toastSuccess } from '../../services/toast.service';
import LocalStorageService from '../../services/storage.service';
import { fetchNLISPIC } from '../../services/mock.service';
import { registerDLP } from '../../services/registerPassport.service';
import ReceiveCattle from './ReceiveCattle/ReceiveCattle';

export type ConsignmentPIC = { consignmentNumber: string; PIC: string };

const handleConsignmentPICs = async (consignmentPICs: ConsignmentPIC[], onSuccess: () => void) => {
    const consignmentPICsMap: { [k: string]: ConsignmentPIC } = consignmentPICs.reduce(
        (acc: any, { consignmentNumber, PIC }) => {
            const key = `${consignmentNumber}-${PIC}`;
            acc[key] = { consignmentNumber, PIC };
            return acc;
        },
        {},
    );

    const uniqueConsignmentPICs = Object.values(consignmentPICsMap);

    await Promise.all(
        uniqueConsignmentPICs.map(async (consigmentPIC) => {
            const { consignmentNumber, PIC } = consigmentPIC;
            await processNLISIDs(consignmentNumber, PIC);
        }),
    );
    onSuccess();
};

const processNLISIDs = async (consignmentId: string, PIC: string) => {
    const nlisPIC = await fetchNLISPIC(consignmentId, PIC);
    const subset = nlisPIC[0]?.['Value']['SubSet'][0];
    const itemList: any[] = [];

    let EPCISTransactionEvent;
    try {
        EPCISTransactionEvent = await publicAPI.get(subset.DigitalLinkResolver);
    } catch (error: any) {
        console.error(error);
        throw new Error('Error get list nlisid');
    }

    const deduplicationItemList = EPCISTransactionEvent.credentialSubject.itemList.reduce((acc: any, cur: any) => {
        const key = cur.itemID;
        acc[key] = cur;
        return acc;
    }, {});

    itemList.push(...Object.values(deduplicationItemList));

    let dlpByNLISID = await Promise.all(
        itemList.map(async (item) => {
            return await processNLISID(item.link as string, subset.DigitalLinkResolver);
        }),
    );
    dlpByNLISID = dlpByNLISID.reduce((acc: any, cur: any) => {
        return { ...acc, ...cur };
    }, {});

    LocalStorageService.set('cattleByNLISID', JSON.stringify(dlpByNLISID));
};

const processNLISID = async (dlpDLR: string, transactionEventDLR: string) => {
    let farmDLP: any;
    try {
        const isVerifyPage = dlpDLR.includes(process.env.REACT_APP_VERIFICATION_PAGE!);
        if (isVerifyPage) {
            const query = dlpDLR.split('?q=')[1];
            const { payload } = JSON.parse(decodeURIComponent(query));
            const { uri } = payload;
            const response: any = await publicAPI.get(uri);
            farmDLP = response;
        } else {
            farmDLP = await publicAPI.get(dlpDLR);
        }
    } catch (error: any) {
        console.error(error);
        throw new Error('Error to get DLP');
    }

    let formattedTransactionEventDLR = transactionEventDLR;

    // Construct verify page URL for TP
    try {
        const isTPDLR = transactionEventDLR.includes(process.env.REACT_APP_TP_LINK_RESOLVER_URL!);

        if (isTPDLR) {
            const payload = JSON.stringify({ payload: { uri: transactionEventDLR } });
            const query = encodeURIComponent(payload);

            formattedTransactionEventDLR = `${process.env.REACT_APP_VERIFICATION_PAGE}?q=${query}`;
        }
    } catch (error: any) {
        console.error(error);
        throw new Error('Error constructing transaction event verify page URL for TP');
    }

    const additionalData = {
        traceabilityInfo: [
            {
                EventReference: formattedTransactionEventDLR,
                EventType: EPCISEventType.Transaction,
            },
        ],
    };

    const cattleByNLISID: { [k: string]: any } = JSON.parse(LocalStorageService.get('cattleByNLISID') || '{}');

    if (farmDLP.credentialSubject.herd.NLIS instanceof Array) {
        await Promise.all(
            farmDLP.credentialSubject.herd.NLIS.map(async (nlisid: string) => {
                const dlp: any = { ...farmDLP };
                dlp.credentialSubject.herd.NLIS = nlisid;

                const feedlotDLP: any = await issueDLP(dlp, additionalData);
                cattleByNLISID[nlisid] = feedlotDLP;
            }),
        );
    } else {
        const feedlotDLP: any = await issueDLP(farmDLP, additionalData);
        cattleByNLISID[farmDLP.credentialSubject.herd.NLIS] = feedlotDLP;
    }
    return cattleByNLISID;
};

const issueDLP = async (farmDLP: any, additionalData: any) => {
    const dlpSubject = constructDLPSubject(farmDLP, additionalData);
    const issuers = process.env.REACT_APP_LIST_ISSUERS?.split(',');
    const credentialPayload = {
        issuer: issuers![0],
    };

    return await registerDLP(dlpSubject, credentialPayload);
};

const constructDLPSubject = (farmDLP: VerifiableCredential, additionalData: any) => {
    const now = moment.utc();
    const dlpSubject = { ...farmDLP.credentialSubject };

    delete dlpSubject.id;
    dlpSubject['herd']['manufacturedAt'] = {
        name: 'Fabulous Feeders',
        operatedBy: { name: 'Fabulous Feeders' },
    };
    dlpSubject['herd']['manufacturedDate'] = now.format('YYYY-MM-DD');
    dlpSubject['herd']['traceabilityInfo'] = additionalData.traceabilityInfo;
    dlpSubject['herd']['sustainabilityInfo'] = [
        {
            Topic: 'environment.deforestation',
            Criteria: 'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32023R1115',
            Evidence:
                'https://web.agtrace.showthething.com/verify?q=%7B%22payload%22%3A%20%7B%22uri%22%3A%22https%3A%2F%2Fagtrace-verifiable-credentials.s3.ap-southeast-2.amazonaws.com%2Ffeedlot-df-cert.json%22%7D%7D',
            metric: [
                {
                    Metric: 'Canopy cover reduction',
                    Unit: '% per km^2',
                    Value: 0,
                },
            ],
            compliance: true,
        },
    ];

    return dlpSubject;
};

const ImportingLivestock = () => {
    const [eNVDs, setENVDs] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [numberOfFiles, setNumberOfFiles] = React.useState(0);

    useEffect(() => {
        if (numberOfFiles && eNVDs.length === numberOfFiles) {
            LocalStorageService.set('uploadedENVDs', JSON.stringify(eNVDs));

            const consignmentPICs: ConsignmentPIC[] = eNVDs.map((eNVD) => {
                return {
                    consignmentNumber: eNVD.credentialSubject.consignmentNumber,
                    PIC: eNVD.credentialSubject.origin.pic,
                };
            });
            handleConsignmentPICs(consignmentPICs, () => {
                setLoading(false);
                toastSuccess('Successfully received the shipment of cattle.');
            });
        }
    }, [eNVDs, numberOfFiles]);

    /**
     * handleFileUpload is used to handle the file JSON upload and using consignmentNumber to get NLISID list from JSON file.
     */
    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setLoading(true);
        const files = e.target.files;
        setNumberOfFiles(files.length);
        const eNVDs: any[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const fileReader = new FileReader();
            fileReader.onload = (event: ProgressEvent<FileReader>) => {
                const fileContent = event?.target?.result;
                const contentJsonFile = JSON.parse(fileContent as string);
                eNVDs.push(contentJsonFile);
                setENVDs((prev) => [...prev, contentJsonFile]);
            };

            fileReader.readAsText(file);
        }
    };

    return (
        <Box
            sx={{
                paddingTop: '40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                <LoadingButton
                    loading={loading}
                    component='label'
                    variant='outlined'
                    startIcon={<UploadFileIcon />}
                    sx={{ margin: '0 5px' }}
                >
                    Upload eNVD VC
                    <input type='file' accept='.json' hidden onChange={handleFileUpload} multiple />
                </LoadingButton>
                <ReceiveCattle />
            </Box>
        </Box>
    );
};

export { ImportingLivestock, handleConsignmentPICs };
